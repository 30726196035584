import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Form, Button, Table, Spinner, Alert } from 'react-bootstrap';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import axios from 'axios';
//import { FaBars } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
      <span>
          Search: {' '}
          <input
              value={globalFilter || ''}
              onChange={(e) => setGlobalFilter(e.target.value || undefined)}
              placeholder="Type to search..."
              style={{ fontSize: '12px', marginBottom: '10px' }}
          />
      </span>
  );
};

const UpdateLocation = ({ username, area_flag, region, branch }) => { 
  const [outletId, setOutletId] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_SERVER}/data-request-location`, {
        params: { username }
    })
    .then(response => {
        setUsers(response.data);
        setLoading(false);
    })
    .catch(error => {
        console.error('There was an error fetching the users!', error);
        setLoading(false);
    });
  }, [username, refreshData]);

  const data = React.useMemo(() => users.map((user, index) => ({ ...user, index: index + 1 })), [users]);
  const columns = React.useMemo(
      () => [
          { Header: 'NO', accessor: 'index', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
          { Header: 'OUTLET ID', accessor: 'outlet_id', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },            
          { Header: 'NAMA OUTLET', accessor: 'nama_outlet', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },          
          { Header: 'CLUSTER NAME', accessor: 'cluster_name', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
          { Header: 'KOTA', accessor: 'kota', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
          { Header: 'KECAMATAN', accessor: 'kecamatan', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
          { Header: 'KELURAHAN', accessor: 'kelurahan', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
          { Header: 'LONGITUDE', accessor: 'longitude', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
          { Header: 'LATTITUDE', accessor: 'latitude', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
          { Header: 'STATUS', accessor: 'status', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
          { Header: 'REQUEST DATE', accessor: 'tgl_create', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
          { Header: 'APPROVED DATE', accessor: 'tgl_update', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },          
          { Header: 'EVENT DATE', accessor: 'event_date', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },          
      ],
      []        
  );

  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      state: { pageIndex, pageSize, globalFilter },
      setPageSize,
      setGlobalFilter,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      gotoPage,
      nextPage,
      previousPage,
  } = useTable(
      {
          columns,
          data,
          initialState: { pageIndex: 0, pageSize: 5 },
      },
      useFilters,
      useGlobalFilter, // Tambahkan useGlobalFilter
      useSortBy,
      usePagination
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    // Rentang longitude dan latitude untuk wilayah Indonesia
    const minLongitude = 95;
    const maxLongitude = 141;
    const minLatitude = -11;
    const maxLatitude = 6;

    // Konversi longitude dan latitude ke Number untuk pengecekan rentang
    const longitudeNum = parseFloat(longitude);
    const latitudeNum = parseFloat(latitude);

    if (isNaN(longitudeNum) || longitudeNum < minLongitude || longitudeNum > maxLongitude) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Longitude harus dalam rentang 95 hingga 141 untuk wilayah Indonesia',
      });
      return;
    }
  
    if (isNaN(latitudeNum) || latitudeNum < minLatitude || latitudeNum > maxLatitude) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Latitude harus dalam rentang -11 hingga 6 untuk wilayah Indonesia',
      });
      return;
    }
    
    const data = {
      outletId,
      username,
      longitude,
      latitude,
      area_flag,
      region,
      branch
    }

    axios.post(`${process.env.REACT_APP_SERVER}/insert-request-location`, data)
      .then(response => {
          if (response.data.success) {
              Swal.fire({
                  icon: 'success',
                  title: 'New User Created Successfully',
                  showConfirmButton: true,
                  timer: 1500
              });
              setOutletId('');
              setLongitude('');
              setLatitude('');
              setRefreshData(!refreshData);
          } else {
              //alert('Error: ' + response.data.message);
              Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: response.data.message,
              });
          }
      })
      .catch(error => {
          if (error.response) {
              //alert('User dengan nomor handphone ini sudah ada.');
              Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: error.response.data.message,
              });          
          }
      });
  };

  return (
    <main className="content m-0 mt-4 p-0 vh-100" style={{ overflowY: 'auto' }}>
      <div className="row mx-4 p-3">
        <Alert key="primary" variant="primary" className="mb-0">
          Setiap <b>OUTLET ID</b> hanya diberikan kesempatan 1 kali untuk request perubahan koordinat lokasi.
        </Alert>
      </div>

      <div className="row mx-1 px-4">
        <div className="col-xl-12 col-xxl-12">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title my-2">FORM PERUBAHAN OUTLET LOCATION</h5>
            </div>
            <div className="card-body pt-2 pb-3">
              {/* Bootstrap Form */}
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  {/* Outlet ID */}
                  <Col md={3}>
                    <Form.Group controlId="formOutletId">
                      <Form.Label>OUTLET ID</Form.Label>
                      <Form.Control 
                        type="number" 
                        placeholder="Enter Outlet ID" 
                        value={outletId}
                        onChange={(e) => setOutletId(e.target.value)}
                        required 
                      />
                    </Form.Group>
                  </Col>

                  {/* Longitude */}
                  <Col md={3}>
                    <Form.Group controlId="formLongitude">
                      <Form.Label>LONGITUDE</Form.Label>
                      <Form.Control 
                        type="text" 
                        placeholder="Enter Longitude" 
                        value={longitude}
                        onChange={(e) => setLongitude(e.target.value)}
                        required 
                      />
                    </Form.Group>
                  </Col>

                  {/* Latitude */}
                  <Col md={3}>
                    <Form.Group controlId="formLatitude">
                      <Form.Label>LATITUDE</Form.Label>
                      <Form.Control 
                        type="text" 
                        placeholder="Enter Latitude" 
                        value={latitude}
                        onChange={(e) => setLatitude(e.target.value)}
                        required 
                      />
                    </Form.Group>
                  </Col>

                  {/* Submit Button */}
                  <Col md={3} className="d-flex align-items-end">
                    <Button variant="danger" type="submit" className="w-100">
                      Submit Request
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="row mx-1 px-4">
        <div className="col-xl-12 col-xxl-12">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title my-2">TABLE LIST - REQUEST UPDATE LOCATION</h5>
            </div>
            <div className="card-body pt-2 pb-3" style={{ fontSize:'12px'}}>
              <Container>                
                <Row>
                    <Col>
                        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                        {loading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                <Spinner animation="border" variant="danger" />
                            </div>
                        ) : (
                            <Table {...getTableProps()} striped bordered hover className="custom-table" style={{ fontSize: '10px' }}>
                                <thead className="custom-thead text-center">
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    className={`${column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''} text-center`}
                                                >
                                                    {column.render('Header')}
                                                    <span className="sort-arrow"></span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()} className="p-1">{cell.render('Cell')}</td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        )}
                    </Col>
                </Row>

                <Row className="d-flex align-items-center">
                    <Col xs={8} className="d-flex align-items-center">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<<'}</button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<'}</button>
                        <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'>'}</button>
                        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} className="btn btn-danger" style={{ fontSize: '12px' }}>{'>>'}</button>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center justify-content-end" style={{ fontSize: '12px' }}>
                        <span>Page {pageIndex + 1} of {pageOptions.length}</span>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))} className="form-select ms-2" style={{ fontSize: '12px' }}>
                            {[5, 10, 20].map(size => (
                                <option key={size} value={size}>Show {size}</option>
                            ))}
                        </select>
                    </Col>
                </Row>
              </Container>            
            </div>
          </div>
        </div>
      </div>

      
    </main>
  );
};

export default UpdateLocation;
